import React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { useTranslation } from "next-i18next";
import { loginRequest } from "../../services/azure/authConfig";
import Link from "next/link";
import { useIsAuthenticated } from "@azure/msal-react";
import { useUserInfo } from "../../services/apiHooks";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

const SignOutButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const { user, isUserInfoLoading, isUserInfoError } =
    useUserInfo(isAuthenticated);
    const isHR = user?.role === "Organizer Manager";

  const { instance, accounts, inProgress } = useMsal();
  const router = useRouter();
  const account = useAccount(accounts[0] || {});
  const { t } = useTranslation("common");

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        ...loginRequest,
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        ...loginRequest,
        postLogoutRedirectUri: `/${router.locale}`,
      });
    }
    Cookies.remove("access_token");
  };

  return (
    <>
      <div className="has-dropdown logindropdown  py-2">
        <div className="d-flex username-container">
          <img src="/assets/svg/sign-in.svg" alt="sign-in-logo" width="28" />
          <a
            className="mb-0 ps-2 ps-ar-2 text-nowrap"
            style={{ cursor: "pointer" }}
          >
            {user?.personal_info?.first_name}
          </a>
        </div>
        <div className="free-width pt-3 pt-md-0">
          <ul className="w-100 flex-md-column flex-row">
            {/*<li className="align-items-center">*/}
            {/*    <div className="image">*/}
            {/*        <svg*/}
            {/*            xmlns="http://www.w3.org/2000/svg"*/}
            {/*            width="19.436"*/}
            {/*            height="14.577"*/}
            {/*            viewBox="0 0 19.436 14.577"*/}
            {/*        >*/}
            {/*            <path*/}
            {/*                id="Icon_awesome-chart-area"*/}
            {/*                data-name="Icon awesome-chart-area"*/}
            {/*                d="M18.98,16.647a.457.457,0,0,1,.456.456v1.518a.457.457,0,0,1-.456.456H.456A.457.457,0,0,1,0,18.621V4.956A.457.457,0,0,1,.456,4.5H1.974a.457.457,0,0,1,.456.456V16.647ZM14.148,8.125,10.932,10.27,7.695,5.954a.456.456,0,0,0-.755.038L3.644,11.485v3.948H18.221L14.808,8.3a.456.456,0,0,0-.661-.178Z"*/}
            {/*                transform="translate(0 -4.5)"*/}
            {/*                fill="#d5cc65"*/}
            {/*            />*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*    <Link href="/dashboard">*/}
            {/*        <a>My Dashboard</a>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/* <li>
              <div className="image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.69"
                  height="21"
                  viewBox="0 0 19.69 21"
                >
                  <path
                    id="Icon_awesome-shield-alt"
                    data-name="Icon awesome-shield-alt"
                    d="M19.6,3.434,11.729.153a1.975,1.975,0,0,0-1.514,0L2.339,3.434A1.967,1.967,0,0,0,1.125,5.251c0,8.142,4.7,13.77,9.086,15.6a1.975,1.975,0,0,0,1.514,0c3.515-1.464,9.09-6.522,9.09-15.6A1.969,1.969,0,0,0,19.6,3.434ZM10.974,18.308l0-15.629,7.215,3.007c-.135,6.21-3.368,10.71-7.211,12.622Z"
                    transform="translate(-1.125 -0.002)"
                    fill="#707070"
                  />
                </svg>
              </div>
              <Link href="">
                <a>Apply for HC Accreditation</a>
              </Link>
            </li>
            <li>
              <div className="image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.436"
                  height="19.441"
                  viewBox="0 0 19.436 19.441"
                >
                  <path
                    id="Icon_ionic-ios-settings"
                    data-name="Icon ionic-ios-settings"
                    d="M22.331,14.218a2.5,2.5,0,0,1,1.6-2.333,9.913,9.913,0,0,0-1.2-2.89,2.534,2.534,0,0,1-1.017.218A2.5,2.5,0,0,1,19.436,5.7a9.883,9.883,0,0,0-2.885-1.2,2.5,2.5,0,0,1-4.667,0,9.913,9.913,0,0,0-2.89,1.2A2.5,2.5,0,0,1,6.712,9.212a2.452,2.452,0,0,1-1.017-.218A10.132,10.132,0,0,0,4.5,11.89a2.5,2.5,0,0,1,.005,4.667,9.913,9.913,0,0,0,1.2,2.89A2.5,2.5,0,0,1,9,22.741a9.971,9.971,0,0,0,2.89,1.2,2.494,2.494,0,0,1,4.656,0,9.913,9.913,0,0,0,2.89-1.2,2.5,2.5,0,0,1,3.295-3.295,9.971,9.971,0,0,0,1.2-2.89A2.513,2.513,0,0,1,22.331,14.218Zm-8.068,4.044a4.049,4.049,0,1,1,4.049-4.049A4.048,4.048,0,0,1,14.263,18.262Z"
                    transform="translate(-4.5 -4.5)"
                    fill="#707070"
                  />
                </svg>
              </div>
              <Link href="">
                <a>Settings</a>
              </Link>
            </li> */}
            {isAuthenticated ? (
              <li className="align-items-center">
                <div className="image d-flex">
                  <img
                    src="/assets/svg/sign-in.svg"
                    alt="sign-in-logo"
                    width="28"
                  /> 
                   <Link href={isHR? "/hr-dashboard" : "/dashboard"}>
                  <a className="text-nowrap">{t("dashboard")}</a>
                  </Link>
                </div>
              </li>
            ) : (
              ""
            )}
            <li className="align-items-center">
              <div className="image d-flex flex-wrap align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.436"
                  height="17.007"
                  viewBox="0 0 19.436 17.007"
                >
                  <path
                    id="Icon_open-account-logout"
                    data-name="Icon open-account-logout"
                    d="M7.289,0V2.43h9.718V14.577H7.289v2.43H19.436V0ZM4.859,4.859,0,8.5l4.859,3.644V9.718h9.718V7.289H4.859Z"
                    fill="#d5cc65"
                  />
                </svg>
                <a
                onClick={() => handleLogout("redirect")}
                className="text-nowrap"
              >
                {t("signout")}
              </a>
              </div>
             
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SignOutButton;
